//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import firebase from 'firebase'
import 'firebase/firestore'
import { setCulture, L10n } from '@syncfusion/ej2-base'
L10n.load({
  'ar-AE': {
    spreadsheet: {
      File: 'ملف',
      Home: 'الرئيسية',
      Insert: 'إدراج',
      Formulas: 'الصيغ',
      View: 'معاينة',
      Data: 'البيانات',
      Cut: 'قطع',
      Copy: 'نسخ',
      Paste: 'معجون',
      PasteSpecial: 'لصق خاص',
      All: 'جميع',
      Values: 'القيم',
      Formats: 'شكل',
      Font: 'الخط',
      FontSize: 'حجم الخط',
      Bold: 'جريء',
      Italic: 'مائل',
      Underline: 'أكد',
      Strikethrough: 'يتوسطه',
      TextColor: 'لون الخط',
      FillColor: 'لون التعبئة',
      HorizontalAlignment: 'المحاذاة الأفقية',
      AlignLeft: 'محاذاة إلى اليسار',
      AlignCenter: 'مركز',
      AlignRight: 'محاذاة إلى اليمين',
      VerticalAlignment: 'محاذاة عمودية',
      AlignTop: 'محاذاة أعلى',
      AlignMiddle: 'محاذاة الأوسط',
      AlignBottom: 'أسفل محاذاة',
      InsertFunction: 'إدراج وظيفة',
      Delete: 'حذف',
      Rename: 'إعادة تسمية',
      Hide: 'إخفاء',
      Unhide: 'ظهار',
      Save: 'حفظ'
    }
  }
})

L10n.load({
  en: {
    spreadsheet: {
      File: 'File',
      Home: 'Home',
      Insert: 'Insert',
      Formulas: 'Formulas',
      View: 'View',
      Data: 'Data',
      Cut: 'Cut',
      Copy: 'Copy',
      Paste: 'Paste',
      PasteSpecial: 'PasteSpecial',
      All: 'All',
      Values: 'Values',
      Formats: 'Formats',
      Font: 'Font',
      FontSize: 'Font Size',
      Bold: 'Bold',
      Italic: 'Italic',
      Underline: 'Underline',
      Strikethrough: 'Strike through',
      TextColor: 'Text Color',
      FillColor: 'Fill Color',
      HorizontalAlignment: 'Horizontal Alignment',
      AlignLeft: 'Align Left',
      AlignCenter: 'Align Center',
      AlignRight: 'Align Right',
      VerticalAlignment: 'Vertical Alignment',
      AlignTop: 'Align Top',
      AlignMiddle: 'Align Middle',
      AlignBottom: 'Align Bottom',
      InsertFunction: 'Insert Function',
      Delete: 'Delete',
      Rename: 'Rename',
      Hide: 'Hide',
      Unhide: 'Unhide',
      Save: 'Save'
    }
  }
})

import { createElement, getComponent, print } from '@syncfusion/ej2-base';
var printElement = createElement("div", {
  className: "e-sheet-panel",
  innerHTML: '<div class="e-spreadsheet-print"></div><div class="e-sheet"><div class="e-main-panel style="height:100%" style="overflow: unset"><div class="e-sheet-content" ></div></div></div>'
}) // creating same hierarchy of element as DOM
// var isPrint = false;

function getFileName(fullName) {
  return fullName.substring(0, fullName.lastIndexOf('.'))
}
function getFileExtension(fullName) {
  // const fileExt = filename.split('.').pop();
  // const ext = fullName.substr(fullName.lastIndexOf('.') + 1);
  const reg = /(?:\.([^.]+))?$/; 
  const ext = reg.exec(fullName)[1];
  return ext
}
export default {
  name: 'app-spreadsheet',

  data() {
    return {
      contentChanged: false,
      doneLoading: false,
      isArabic: false,
      fileName: 'Sample',
      extension: 'xlsx',
      openUrl: 'https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/open',
      saveUrl: 'https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/save',
      
      iconStyle: 'float:rightbackground: transparentbox-shadow:noneborder-color: transparentborder-radius: 2pxcolor:inheritfont-size:12pxtext-transform:capitalizemargin-top:4pxheight:28pxfont-weight:400font-family:inherit',
      titleStyle: 'text-transform:capitalizefont-weight:400font-family:inherittext-overflow:ellipsiswhite-space:preoverflow:hiddenuser-select:nonecursor:text',
      saveIconCss: 'e-de-icon-Print e-de-padding-right e-de-padding-left',
      printIconCss: 'e-de-icon-Print e-de-padding-right e-de-padding-left',
      exportIconCss: 'e-de-icon-Download e-de-padding-right e-de-padding-left',
      exportItems: [
        { text: 'Microsoft Word (.docx)', id: 'word' },
        // { text: 'Syncfusion Document Text (.sfdt)', id: 'sfdt' }
      ],
      printItems: [
        { text: 'Print' },
        { text: 'Print All' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      record: 'editor/record',
      findLoading: 'editor/findLoading',
      saveLoading: 'editor/saveLoading',
      currentUser: 'auth/currentUser',
    }),
    language() {
      return this.$i18n.locale
    },
    locale() {
      return this.$i18n.locale == 'ar' ? 'ar-AE' : 'en' 
      // return this.isArabic ? 'ar-AE' : 'en' 
    },
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    id() {
      return this.$route.params.id
    },
    hasDownloadAccess() {
      if (!this.currentUser) return false
      if (this.currentUser.roles != 'Employee') return true
      return this.record && this.record.downloadAccess.includes(this.currentUser.id)
        ? true
        : false
    },
    hasPrintAccess() {
      if (!this.currentUser) return false
      if (this.currentUser.roles != 'Employee') return true
      return this.record && this.record.printAccess.includes(this.currentUser.id)
        ? true
        : false
    },
  },
  watch: {
    isRTL: {
      handler: function (newval) {
        newval ? setCulture('ar-AE') : setCulture('en')
      },
      deep: true
    }
  },
  mounted() {
    this.doFindFile(this.id)
  },
  methods: {
    ...mapActions({
      doFind: 'editor/doFind'
    }),
    async doFindFile(id) {
      if (id) {
        await this.doFind(id)
      }

      if (this.record) {
        const FILE_NAME = this.record.name
        this.fileName = getFileName(FILE_NAME)
        this.extension = getFileExtension(FILE_NAME)
        
        this.record.jsonObject
          ? this.loadJson()
          : this.openFile()
      } 
    },
    openFile() {
      const URL = this.record.publicUrl
      fetch(URL).then(response => { // fetch the remote url
        response.blob().then(fileBlob => { // convert the excel file to blob
          const file = new File([fileBlob], 'Sample.xlsx'); //convert the blob into file
          this.$refs.spreadsheet.open({ file: file }); // open the file into Spreadsheet
        })
      })
    },
    loadJson() {
      const file = this.record.jsonObject
      if (file) {
        this.$refs.spreadsheet.openFromJson({
          file: JSON.parse(file) // Parse the stringified response
        })
      }
    },
    async saveAsJson() {
      this.contentChanged = true
      this.doneLoading = true
      const spreadsheet = await this.$refs.spreadsheet.saveAsJson()
      const jsonObject = spreadsheet.jsonObject
      const batch = firebase.firestore().batch()
      const docRef = firebase.firestore().collection('files').doc(this.id)
      batch.update(docRef, {
        jsonObject: JSON.stringify(jsonObject)
      })
      batch.commit()
      this.contentChanged = false
      setTimeout(() => {
        this.doneLoading = false
      }, 800);
    },
    // async beforeSave(args) {
      
    //   console.log('args =', args)
    //   const workbook = await this.$refs.spreadsheet.save({
    //     url: this.saveUrl,
    //     fileName: this.fileName,
    //     saveType: 'xlsx',
    //     needBlobData: true
    //   })
    //   console.log(workbook)
    // },
    // beforeOpen() {},
    itemSelect: function(args) {
      var spreadsheet = getComponent(document.getElementById("spreadsheet"), "spreadsheet");
      if (args.item.text === 'Print') {
      printElement.querySelector(".e-sheet-content").innerHTML = document.querySelector(
        ".e-sheet-content"
      ).outerHTML; //  To add the spreadsheet table
      var usedRange = spreadsheet.getActiveSheet().usedRange;
      var tbody = printElement.querySelector('tbody');
      for (var i = tbody.getElementsByClassName('e-row').length; i >= 0; i--) {
        if (tbody.getElementsByClassName('e-row')[i] && parseInt(tbody.getElementsByClassName('e-row')[i].getAttribute('aria-rowindex'), 10) > usedRange.rowIndex + 1) {
          tbody.getElementsByClassName('e-row')[i].remove();
        }
      }
      (printElement.querySelector('.e-sheet-content').children[0].getElementsByClassName('e-virtualtrack')[0]).style.height = 'auto';
      print(printElement);
      printElement.querySelector(".e-sheet-content").innerHTML = '';
    }
    if (args.item.text === 'Print All') {
      var sheets = spreadsheet.sheets;
      if (spreadsheet.activeSheetIndex === 0) {
        printElement.querySelector(".e-sheet-content").innerHTML = document.querySelector(
          ".e-sheet-content"
        ).outerHTML; //  To add the spreadsheet table

        var usedRange1 = spreadsheet.getActiveSheet().usedRange;
        var tbody1 = printElement.querySelector('tbody');
        for (var a = tbody1.getElementsByClassName('e-row').length; a >= 0; a--) {
          if (tbody1.getElementsByClassName('e-row')[a] && parseInt(tbody1.getElementsByClassName('e-row')[a].getAttribute('aria-rowindex'), 10) > usedRange1.rowIndex + 1) {
            tbody1.getElementsByClassName('e-row')[a].remove();
          }
        }

        if (sheets[spreadsheet.activeSheetIndex + 1]) {
          spreadsheet.goTo(sheets[spreadsheet.activeSheetIndex + 1].name + "!A1");
          // isPrint = true;
        } else {
          print(printElement);
          printElement.querySelector(".e-sheet-content").innerHTML = '';
        }
      } else {
        if (sheets[0]) {
          spreadsheet.goTo(sheets[0].name + "!A1");
          // isPrint = true;
        }
      }
    }
    },
    onExport: function (args) {
      switch (args.item.id) {
        case 'word':
          this.save('Docx')
          break
        case 'sfdt':
          this.save('Sfdt')
          break
      }
    },
    // openExportDropDown: function () {
    //   // tslint:disable-next-line:max-line-length
    //   document.getElementById('word').setAttribute('title', 'Download a copy of this document to your computer as a DOCX file.')
    //   // tslint:disable-next-line:max-line-length
    //   document.getElementById('sfdt').setAttribute('title', 'Download a copy of this document to your computer as an SFDT file.')
    // },
    openBtnClick: function () {
      this.$refs.uploadDocument.click()
    },
    printBtnClick: function () {
      this.$refs.documenteditor.ej2Instances.documentEditor.print()
    },
    titleBarKeydownEvent: function (e) {
      if (e.keyCode === 13) {
        e.preventDefault()
        document.getElementById("documenteditor_title_contentEditor").contentEditable = 'false'
        if (document.getElementById("documenteditor_title_contentEditor").textContent === '') {
          document.getElementById("documenteditor_title_contentEditor").textContent = 'Document1'
        }
        this.fileName = document.getElementById("documenteditor_title_contentEditor").textContent
      }
    },
    titleBarBlurEvent: function () {
      
      // console.log(args);
      if (document.getElementById("documenteditor_title_contentEditor").textContent === '') {
        document.getElementById("documenteditor_title_contentEditor").textContent = 'Document1'
      }
      document.getElementById("documenteditor_title_contentEditor").contentEditable = 'false'
      this.$refs.documenteditor.ej2Instances.documentEditor.documentName = document.getElementById("documenteditor_title_name").textContent
    },
    titleBarClickEvent: function () {
      this.updateEditorTitle()
    },
    updateEditorTitle: function () {
      document.getElementById("documenteditor_title_contentEditor").contentEditable = 'true'
      document.getElementById("documenteditor_title_contentEditor").focus()
      window.getSelection().selectAllChildren(document.getElementById("documenteditor_title_contentEditor"))
    },
    documentChangedEvent: function () {
      
      const editor = this.$refs.documenteditor.ej2Instances.documentEditor
      this.documentTitle = editor.documentName === '' ? this.documentTitle : editor.documentName
      document.getElementById("documenteditor_title_name").textContent = this.documentTitle
      setTimeout(() => { editor.scrollToPage(1) }, 10)
    },
    updateEditorSize: function () {
      // Resizes the document editor component to fit full browser window. (Reduced title bar left padding which is 18)
      let windowWidth = window.innerWidth - 18
      // Reducing the size of title bar, to fit Document editor component in remaining height.
      let windowHeight = window.innerHeight - (document.getElementById("documenteditor_titlebar").offsetHeight + 26)
      this.$refs.documenteditor.ej2Instances.resize(windowWidth, windowHeight)
    },
    onWindowResize: function () {
      // Resizes the document editor component to fit full browser window automatically whenever the browser resized.
      this.updateEditorSize()
    },
  }
}
